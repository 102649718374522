export const clearContextAndLocalStorage = () => {
	// Limpiar todo el LocalStorage
	// localStorage.clear();

	// Inicializo las variables del localStorage a vacío
	localStorage.setItem('companyId', '');
	localStorage.setItem('userId', '');
	localStorage.setItem('userAccountType', '');
	localStorage.setItem('language', '');
	localStorage.setItem('token', '');
};
