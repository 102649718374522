import LoginForm from '../components/LoginForm';

const Login = ({ token }: { token?: string }) => {
	return (
		<>
			<LoginForm token={token} />
		</>
	);
};

export default Login;
