const syncAllMovements = async (token: string) => {
	const response = await fetch(`${process.env.REACT_APP_BASE_API}/users/sync-all-movements`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	});

	return response.json();
};

export default syncAllMovements;
