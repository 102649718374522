const acceptUserInvitation = async (invitationToken: string) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_API}/users/accept-invitation/${invitationToken}`,
		{
			method: 'GET'
		}
	);

	return response.json();
};

export default acceptUserInvitation;
