import { ContributionFromEnum } from '../users/interfaces/ContributionData';

const getCalculatorsAdminAportations = async (
	token: string,
	page: number,
	limit: number,
	typeContribution?: ContributionFromEnum
) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_API}/calculators/all-aportations-by-type`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			body: JSON.stringify({ contributionType: typeContribution, page, limit })
		}
	);

	return response.json();
};

export default getCalculatorsAdminAportations;
