import { GenericBackendResponse } from '../genericBackendResponse';

export interface PortfolioLine {
	lineId: string;
	portfolioName: string;
	portfolioCode: string;
	name: string;
	isin: string;
	assetClass: string;
	indexName: string;
	currency: string;
	sizeMeur: string;
	expenses: string;
	trackingError: string;
	weight: string;
}

export interface Portfolio {
	portfolioCode: string;
	lines: PortfolioLine[];
}

export type GetPortfolioLinesResponse = GenericBackendResponse<Portfolio[]>;

const getPortfolioLines = async (token: string): Promise<GetPortfolioLinesResponse> => {
	const response = await fetch(`${process.env.REACT_APP_BASE_API}/calculators/portfolio-lines`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	});

	if (!response.ok) {
		throw new Error('Network response was not ok');
	}

	const result: GetPortfolioLinesResponse = await response.json();
	return result;
};

export default getPortfolioLines;
