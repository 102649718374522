import React, { useState } from 'react';
import {
	Box,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Heading,
	Link,
	Text,
	Button,
	IconButton,
	background
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import ProjectConfig from '../../../constants';
import InfoIconClick from '../infoIconClick';
import { useNavigate } from 'react-router';

interface BenUserCardHiddenProps {
	heading?: any;
	cardBody: any;
	infoIconVisible: boolean;
	infoIconText: string;
	linkText?: string;
	link?: string;
	width?: string;
	height?: string;
	minW?: string;
}

const BenUserCardHidden: React.FC<BenUserCardHiddenProps> = ({
	heading,
	cardBody,
	infoIconVisible,
	infoIconText,
	linkText,
	link,
	width,
	height = '190px',
	minW = '200px'
}: BenUserCardHiddenProps) => {
	const [isVisible, setIsVisible] = useState(false);
	const navigate = useNavigate();

	const toggleVisibility = () => {
		setIsVisible(!isVisible);
	};

	const maskAccountNumber = (accountNumber: string) => {
		const visibleDigits = 4;
		const maskedSection = accountNumber.slice(0, -visibleDigits).replace(/./g, '*');
		const visibleSection = accountNumber.slice(-visibleDigits);
		return `${maskedSection}${visibleSection}`;
	};

	return (
		<Card
			display="flex"
			flexDirection="column"
			borderRadius="16px"
			minW={minW}
			height={height}
			backgroundColor={ProjectConfig.ARCA_COLORS.WHITE}
			width={width}>
			<CardHeader
				display="flex"
				alignItems="start"
				justifyContent="space-between"
				p="24px 24px 0px 24px">
				<Heading fontFamily={ProjectConfig.FONTS.INTER} fontSize="16px" fontWeight="400">
					{heading}
				</Heading>
				<Box fontSize="20px">
					<InfoIconClick isVisible={infoIconVisible} text={infoIconText} />
				</Box>
			</CardHeader>
			<CardBody
				fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
				fontWeight="900"
				display="flex"
				alignItems="flex-end"
				justifyContent="space-between"
				p="0px 15px 25px 20px"
				ml="12px">
				<Text fontSize="32px" flex="1">
					{isVisible
						? cardBody ?? '____ ____ ____ ____ ____ ____'
						: maskAccountNumber(cardBody ?? '____ ____ ____ ____ ____ ____')}
				</Text>
				<IconButton
					width="20px"
					fontSize={40}
					color={ProjectConfig.ARCA_COLORS.PINK_500}
					onClick={toggleVisibility}
					aria-label={isVisible ? 'Ocultar' : 'Mostrar'}
					icon={isVisible ? <ViewOffIcon /> : <ViewIcon />}
					m="0px 0px 16px 16px"
					variant="unstyled"
					_hover={{ background: 'none' }}
				/>
			</CardBody>

			{link && linkText && (
				<CardFooter>{link && <Link onClick={() => navigate(link)}>{linkText}</Link>}</CardFooter>
			)}
		</Card>
	);
};

export default BenUserCardHidden;
