const getOptionByName = async (token: string, optionName: string) => {
	const response = await fetch(`${process.env.REACT_APP_BASE_API}/options/${optionName}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	});

	return response.json();
};

export default getOptionByName;
