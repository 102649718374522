import React from 'react';
import { Text } from '@chakra-ui/react';
import ProjectConfig from '../../constants';

interface SectionTitleProps {
	text: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ text }) => {
	return (
		<Text
			fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
			fontSize="24px"
			fontWeight="700"
			pb="8px"
			lineHeight="33.6px">
			{text}
		</Text>
	);
};

export default SectionTitle;
