import * as React from 'react';
import * as ES from './ES.json';
import * as EN from './EN.json';

export const translations: any = {
	es: ES,
	en: EN
};
const hasLanguageInLocalStorage = localStorage.getItem('language');
const userLanguage: string = hasLanguageInLocalStorage || 'es';

const getTranslate = (langCode: string) => (key: string) =>
	translations[langCode].default[key] || key;

const defaultValue = {
	langCode: userLanguage, //Indicar el idioma del navegador
	translate: getTranslate(userLanguage)
};

interface LocaleState {
	langCode: string;
	translate: (key: string) => string;
}

interface ContextProps {
	state: LocaleState;
	dispatch: ({ type }: { type: string; payload: string }) => void;
}

export const I18nContext = React.createContext({} as ContextProps);

export const WithI18n = ({ children }: any) => {
	const reducer = (state: any, action: { type: string; payload: string }) => {
		if ('setLanguage' === action.type) {
			return {
				langCode: action.payload,
				translate: getTranslate(action.payload)
			};
		} else {
			return { ...defaultValue };
		}
	};

	const [state, dispatch] = React.useReducer(reducer, defaultValue);

	return <I18nContext.Provider value={{ state, dispatch }}>{children}</I18nContext.Provider>;
};
