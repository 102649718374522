import { GenericBackendResponse } from '../genericBackendResponse';

export interface PensionPlanPerformanceData {
	totalMonths: number;
	aportacionesNetasEmpresa: number[];
	totalCompanyContributions: number;
	totalParticipantsContributions: number;
	aportacionesNetasEmpleados: number[];
	savingsByContingencesReduction: number;
	corporateTaxDeduction: number;
	planPerformance: {
		percentage: number;
		eurosAmount: number;
	};
	nextContribution: {
		nextContributionDate: string;
		promoterAmount: number;
		participantsAmount: number;
	};
}

export type GetPensionPlanPerformanceResponse = GenericBackendResponse<PensionPlanPerformanceData>;

const getPensionPlanPerformance = async (
	token: string
): Promise<GetPensionPlanPerformanceResponse> => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_API}/calculators/pension-plan-performance`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		}
	);

	if (!response.ok) {
		throw new Error('Network response was not ok');
	}

	const result: GetPensionPlanPerformanceResponse = await response.json();
	return result;
};

export default getPensionPlanPerformance;
