import ProjectConfig from '../../constants';

const CustomProgress: React.FC<{ value: number; max: number }> = ({ value, max }) => {
	const adjustedMax = max === 0 ? 100 : max;
	return (
		<div
			style={{
				height: '40px',
				backgroundColor: ProjectConfig.ARCA_COLORS.GRAY,
				borderRadius: '50px',
				overflow: 'hidden' // Para que la barra no sobresalga
			}}>
			<div
				style={{
					height: '100%',
					width: `${(value / adjustedMax) * 100}%`, // Calcula el ancho basado en el valor y el máximo ajustado
					backgroundColor: ProjectConfig.ARCA_COLORS.GREEN_400,
					borderRadius: '25px',
					transition: 'width 0.3s ease' // Animación suave al cambiar el ancho
				}}
			/>
			{/* Mostrar el porcentaje dentro de la barra */}
			<span
				style={{
					position: 'absolute',
					color: 'white', // Color del texto
					fontWeight: 'bold',
					left: '50%',
					top: '50%',
					transform: 'translate(-50%, -50%)'
				}}>
				{Math.round((value / adjustedMax) * 100)}%
			</span>
		</div>
	);
};

export default CustomProgress;
