import { Spinner } from '@chakra-ui/react';
import ProjectConfig from '../../../constants';

const ButtonSpinner: React.FC = () => {
	return (
		<Spinner
			size="lg"
			thickness="7px"
			speed="1s"
			emptyColor={ProjectConfig.ARCA_COLORS.GREEN_400}
			color={ProjectConfig.ARCA_COLORS.GREEN_800}
		/>
	);
};

export default ButtonSpinner;
