import React from 'react';
import { useToast, ToastId, Box } from '@chakra-ui/react';

export enum ToastStatusEnum {
	INFO = 'info',
	WARNING = 'warning',
	SUCCESS = 'success',
	ERROR = 'error',
	LOADING = 'loading'
}

export enum ToastPositionEnum {
	TOP = 'top',
	TOP_RIGHT = 'top-right',
	TOP_LEFT = 'top-left',
	BOTTOM = 'bottom',
	BOTTOM_RIGHT = 'bottom-right',
	BOTTOM_LEFT = 'bottom-left'
}

interface GenericToastProps {
	title: string;
	description: string;
	status?: ToastStatusEnum;
	duration?: number | null;
	isClosable?: boolean;
	position?: ToastPositionEnum;
	showToast: boolean;
}

const GenericToast: React.FC<GenericToastProps> = ({
	title,
	description,
	status,
	duration,
	isClosable,
	position,
	showToast
}: GenericToastProps) => {
	const toast = useToast();

	const durationInMs = duration === null ? null : duration != null ? duration : 2000;

	React.useEffect(() => {
		if (showToast) {
			const toastId = toast({
				title,
				description: <Box dangerouslySetInnerHTML={{ __html: description }} />,
				status,
				duration: durationInMs,
				isClosable: isClosable ?? true,
				position: position ?? ToastPositionEnum.TOP_RIGHT
			});

			// Cleanup function to close the toast when the component unmounts or showToast becomes false
			return () => {
				toast.close(toastId);
			};
		}
	}, [title, description, status, duration, isClosable, position, showToast, toast]);

	return null;
};

export default GenericToast;
