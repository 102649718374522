import React, { useContext, useEffect, useState } from 'react';
import { Box, Text, SimpleGrid } from '@chakra-ui/react';
import { I18nContext } from '../../../context/translations/TranslationsContext.context';
import DocumentDownload from '../../../components/Documents/DocumentDownload';
import LoadingViewSpinner from '../../../components/ChakraUi/Spinners/LoadingViewSpinner';
import getInvoices from '../../../api/invoices/getInvoices';
import { ArcaContext } from '../../../context/ArcaContext.context';

const AdminBilling: React.FC = () => {
	const {
		state: { translate }
	} = React.useContext(I18nContext);

	const { token, companyId } = useContext(ArcaContext);

	const [initialLoading, setInitialLoading] = useState<boolean>(true);
	const [documentLinks, setDocumentLinks] = useState<{ name: string; url: string }[]>([]);

	useEffect(() => {
		const fetchDocuments = async () => {
			// Llamada a la API para obtener las facturas
			const invoicesResponse = await getInvoices(token, companyId); // Asegúrate de definir token y companyId

			// Verificar si la respuesta es exitosa
			if (invoicesResponse.success) {
				const fetchedDocuments = invoicesResponse.data.map((url: string) => ({
					name: url.split('/').pop() || 'Documento', // Cambiado para usar el nombre del documento
					url: url
				}));
				setDocumentLinks(fetchedDocuments);
			} else {
				// Manejo de errores si es necesario
				console.error('Error fetching invoices:', invoicesResponse);
			}
			setInitialLoading(false);
		};

		fetchDocuments();
	}, []);

	return (
		<Box p={6}>
			<Text fontSize="24px" fontWeight="bold">
				{translate('admin.billing.title')}
			</Text>
			<Text fontSize="18px">{translate('admin.billing.subtitle')}</Text>

			{/* Loader mientras se cargan los documentos */}
			{initialLoading ? (
				<Box h="80dvh" display="flex" justifyContent="center" alignItems="center">
					<LoadingViewSpinner />
				</Box>
			) : (
				<SimpleGrid columns={{ base: 1, md: 5 }} spacing={1} mt={4}>
					{documentLinks.map((doc, index) => (
						<DocumentDownload documentName={doc.name} downloadLink={doc.url} />
					))}
				</SimpleGrid>
			)}
		</Box>
	);
};

export default AdminBilling;
