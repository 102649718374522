import './App.css';
import Layout from './components/Layout';
import { Route, Routes } from 'react-router-dom';
import { privateRoutes, publicRoutes, redirectRoutes } from './routes/Routes';
import PrivateRoute from './routes/PrivateRoutes.routes';
import { ChakraBaseProvider, extendBaseTheme, theme as chakraTheme } from '@chakra-ui/react';
import { useContext } from 'react';
import { ArcaContext } from './context/ArcaContext.context';
import '@fontsource/space-grotesk';
import '@fontsource/inter';

const chakraComponents = chakraTheme.components;

const theme = extendBaseTheme({
	components: { ...chakraComponents }
});

// TODO: https://chakra-ui.com/getting-started
// <Component {...pageProps} />

const getRoutes = (routes: any) => {
	return routes.map((route: any, index: number) => (
		<Route key={index} path={route.path} element={route.component}>
			{route.children && getRoutes(route.children)}
		</Route>
	));
};

function App() {
	const { token: tokenContext } = useContext(ArcaContext);
	console.log('tokenContext', tokenContext);

	const NODE_ENV = process.env.NODE_ENV || 'development';
	console.log('NODE_ENV', NODE_ENV);

	const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API || 'https://dev.api.arcadigital.app';
	console.log('REACT_APP_BASE_API', REACT_APP_BASE_API);

	return (
		<ChakraBaseProvider theme={theme}>
			<Layout>
				<Routes>
					{getRoutes(redirectRoutes)}
					{getRoutes(publicRoutes)}
					<Route element={<PrivateRoute token={tokenContext} />}>{getRoutes(privateRoutes)}</Route>
					<Route path="/" element={<PrivateRoute token={tokenContext} />}></Route>
				</Routes>
			</Layout>
		</ChakraBaseProvider>
	);
}

export default App;
