const getCurrentUser = async (token: string) => {
	const response = await fetch(`${process.env.REACT_APP_BASE_API}/users/current-user`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	});

	return response.json();
};

export default getCurrentUser;
