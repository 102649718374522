import {
	Box,
	Card,
	CardBody,
	Link,
	ListItem,
	SimpleGrid,
	Text,
	UnorderedList
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { I18nContext } from '../../../context/translations/TranslationsContext.context';
import SectionTitle from '../../../components/Titles/SectionTitle';
import ProjectConfig from '../../../constants';
import {
	CompanyEppConfigI,
	ContributionPeriodicityEnum,
	EmployeeContributionTypeEnum,
	EmployerContributionTypeEnum
} from '../../../api/companies/interfaces/CompanyEppConfig';
import { CompanyInfoI } from '../../../api/companies/interfaces/CompanyInfo';
import { ArcaContext } from '../../../context/ArcaContext.context';
import getCompanyInfoEppConfig from '../../../api/companies/getCompanyInfoEppConfig';
import LoadingViewSpinner from '../../../components/ChakraUi/Spinners/LoadingViewSpinner';
import DocumentDownload from '../../../components/Documents/DocumentDownload';

const AdminConfigurarBeneficioTab: React.FC = () => {
	const {
		state: { translate }
	} = useContext(I18nContext);

	interface CompanyInfoAndEppConfigI {
		company: CompanyInfoI;
		companyEppConfig: CompanyEppConfigI;
	}

	type Periodicity = 'MONTHLY' | 'QUARTERLY' | 'SEMIANNUALLY' | 'ANNUALLY';

	const { token, companyId } = useContext(ArcaContext);

	const [initialLoading, setInitialLoading] = useState<boolean>(true);

	const [companyEppInfo, setCompanyEppInfo] = useState<CompanyInfoAndEppConfigI>({
		company: {
			id: '',
			createdAt: '',
			updatedAt: '',
			companyEppConfigId: '',
			name: '',
			entityType: '',
			socialDenomination: '',
			NIF: '',
			incorporationDate: '',
			mercantileRegistryCity: '',
			tomo: 0,
			folio: 0,
			section: 0,
			sheet: '',
			registration: 0,
			cnaeCode: 0,
			staffSize: 0,
			fiscalAddress: '',
			postalCode: 0,
			city: '',
			province: '',
			bankAccount: ''
		},
		companyEppConfig: {
			id: '',
			createdAt: '',
			updatedAt: '',
			companyId: '',
			promoterCode: 0,
			newPlan: false,
			transferPlan: false,
			contributionPeriodicity: ContributionPeriodicityEnum.MONTHLY,
			uniformConditions: false,
			salaryBracket: {},
			seniorityBracket: {},
			documents: {},
			employerContributionType: EmployerContributionTypeEnum.FIXED_AMOUNT,
			employerContributionAmount: 0,
			employeeContributionRequired: true,
			employeeContributionType: EmployeeContributionTypeEnum.FIXED,
			employeeContributionMinimum: 0,
			voluntaryEmployeeContributionsAllowed: false,
			caserPlanData: { PLAN: 0, FONDO: 0, SUBPLAN: 0 },
			linkRegulation: '',
			linkAnnex: ''
		}
	});

	useEffect(() => {
		setInitialLoading(true);

		const getCompanyInfoEppConfigAsync = async () => {
			if (typeof companyId === 'string' && companyId.trim().length > 0) {
				try {
					const currentCompanyInfoEppConfig = await getCompanyInfoEppConfig(token, companyId);
					const currentCompanyInfoEppConfigData =
						currentCompanyInfoEppConfig.data as CompanyInfoAndEppConfigI;
					setCompanyEppInfo(currentCompanyInfoEppConfigData);
				} catch (error) {
					console.error('Error fetching company info:', error);
				}
			}
		};
		getCompanyInfoEppConfigAsync();

		setTimeout(() => {
			setInitialLoading(false);
		}, 1000);
	}, [token]);

	const PeriodsListItem = () => {
		const mappingPeriods: Record<ContributionPeriodicityEnum, string> = {
			MONTHLY: 'onboarding.employee.condition2.2',
			QUARTERLY: 'onboarding.employee.condition2.3',
			SEMIANNUALLY: 'onboarding.employee.condition2.4',
			ANNUALLY: 'onboarding.employee.condition2.5'
		};

		const periods = companyEppInfo?.companyEppConfig?.contributionPeriodicity as Periodicity;
		const conditionTranslationKey = mappingPeriods[periods];

		return (
			<ListItem mx="20px">
				{`${translate('onboarding.employee.condition2.1')}`}
				<Text as="span" fontWeight="bold">
					{conditionTranslationKey && translate(conditionTranslationKey)}.
				</Text>
			</ListItem>
		);
	};

	const eppConditionsList = (
		<UnorderedList fontSize="16px">
			<PeriodsListItem />

			<ListItem mx="20px">
				{`${companyEppInfo.company.name} ${translate('onboarding.employee.condition1.1')}`}
				<Text as="span" fontWeight="bold">
					{companyEppInfo.companyEppConfig.employerContributionType ===
					EmployerContributionTypeEnum.PERCENTAGE_AMOUNT
						? translate('onboarding.employee.condition1.2')
						: translate('onboarding.employee.condition1.3')}
					{companyEppInfo?.companyEppConfig?.employerContributionAmount}
					{companyEppInfo?.companyEppConfig?.employerContributionType ===
					EmployerContributionTypeEnum.PERCENTAGE_AMOUNT
						? translate('onboarding.employee.condition4.2')
						: translate('onboarding.employee.condition4.1')}
				</Text>
			</ListItem>
			<ListItem mx="20px">
				{`${translate('onboarding.employee.condition3.1')}`}
				<Text as="span" fontWeight="bold">
					{companyEppInfo?.companyEppConfig?.employeeContributionRequired === false
						? translate('onboarding.employee.condition3.2')
						: translate('onboarding.employee.condition3.3') +
							translate('onboarding.employee.condition3.4') +
							companyEppInfo?.companyEppConfig?.employeeContributionMinimum +
							translate('onboarding.employee.condition4.1')}
				</Text>
			</ListItem>
		</UnorderedList>
	);

	return (
		<div>
			{initialLoading ? (
				<Box h="80dvh" display="flex" justifyContent="center" alignItems="center">
					<LoadingViewSpinner />
				</Box>
			) : (
				<div>
					<SectionTitle text={translate('admin.setBenefit.actualPlanConditions')} />

					<SimpleGrid spacing="10px" minChildWidth="200px" h="90%">
						{/* Card con las condiciones del plan de pensiones de empleo del usuario */}
						<Box textAlign="start" width="fit-content" height="auto">
							<Text p="0px 0px 10px 25px" fontSize="25px"></Text>
							<Box display="flex" maxW="600px" minH="190px" gap="16px">
								<Card backgroundColor={ProjectConfig.ARCA_COLORS.WHITE} borderRadius="12px">
									<CardBody display="flex" alignItems="center" justifyContent="center">
										<Text mt="-10px">{eppConditionsList}</Text>
									</CardBody>
								</Card>
							</Box>
						</Box>
					</SimpleGrid>

					<Text fontSize={20}>
						{translate('user.myContributions.contactWith')}
						<Link href="mailto:soporte@arcadigital.app" textDecoration="underline">
							soporte@arcadigital.app
							{/* TODO HARDCODE  */}
						</Link>
					</Text>

					<SimpleGrid spacing="10px" minChildWidth="200px" h="90%">
						<SectionTitle text={translate('admin.setBenefit.documents')} />
					</SimpleGrid>

					<DocumentDownload
						documentName={translate('admin.setBenefit.reglamento')}
						downloadLink={companyEppInfo.companyEppConfig.linkRegulation}
					/>

					<DocumentDownload
						documentName={translate('admin.setBenefit.anexo')}
						downloadLink={companyEppInfo.companyEppConfig.linkAnnex}
					/>
				</div>
			)}
		</div>
	);
};

export default AdminConfigurarBeneficioTab;
