import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import ProjectConfig from '../../constants';

interface PaginationProps {
	currentPage: number;
	totalPages: number;
	onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
	const handlePrevious = () => {
		if (currentPage > 1) {
			onPageChange(currentPage - 1);
		}
	};

	const handleNext = () => {
		if (currentPage < totalPages) {
			onPageChange(currentPage + 1);
		}
	};

	const handlePageClick = (page: number) => {
		onPageChange(page);
	};

	const renderPageNumbers = () => {
		return Array.from({ length: totalPages }, (_, i) => {
			const pageNumber = i + 1;
			const isCurrentPage = currentPage === pageNumber;
			const bgColor = isCurrentPage ? 'black' : ProjectConfig.ARCA_COLORS.PINK_200;
			const activeDisabledHoverBg = ProjectConfig.ARCA_COLORS.PINK_500;

			return (
				<Button
					key={pageNumber}
					size="sm"
					w="40px"
					marginX="2px"
					borderRadius="40px"
					color="white"
					background={bgColor}
					_active={{ bg: activeDisabledHoverBg }}
					_disabled={{ bg: activeDisabledHoverBg }}
					_hover={{ bg: activeDisabledHoverBg }}
					onClick={() => handlePageClick(pageNumber)}
					isActive={isCurrentPage}
					isDisabled={isCurrentPage}>
					{pageNumber}
				</Button>
			);
		});
	};

	return (
		<Box justifyContent="center">
			<Button
				size="sm"
				w="30px"
				marginX="25px"
				background={ProjectConfig.ARCA_COLORS.PINK_200}
				_hover={{
					bg: ProjectConfig.ARCA_COLORS.PINK_500
				}}
				color="white"
				onClick={handlePrevious}
				sx={currentPage === 1 ? { display: 'none' } : {}}
				isDisabled={currentPage === 1}>
				<Box fontSize="20px">
					<FaChevronLeft />
				</Box>
			</Button>
			{renderPageNumbers()}
			<Button
				size="sm"
				w="30px"
				marginX="25px"
				background={ProjectConfig.ARCA_COLORS.PINK_200}
				_hover={{
					bg: ProjectConfig.ARCA_COLORS.PINK_500
				}}
				color="white"
				onClick={handleNext}
				sx={currentPage === totalPages ? { display: 'none' } : {}}
				isDisabled={currentPage === totalPages}>
				<Box fontSize="20px">
					<FaChevronRight />
				</Box>
			</Button>
		</Box>
	);
};

export default Pagination;
