import { GenericBackendResponse } from '../genericBackendResponse';

export interface CompanyNextContributionInfo {
	nextContributionDate: string;
	promoterAmount: number;
	participantsAmount: number;
}

const getCompanyNextContributionInfo = async (
	token: string
): Promise<GenericBackendResponse<CompanyNextContributionInfo>> => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_API}/companies/company-next-contribution`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		}
	);

	if (!response.ok) {
		throw new Error('Network response was not ok');
	}

	const result = await response.json();
	return result;
};

export default getCompanyNextContributionInfo;
