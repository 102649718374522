const inviteEmployees = async (token: string, companyId: string, emails: string[]) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_API}/companies/invite-employees/${companyId}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			body: JSON.stringify({
				emails
			})
		}
	);

	return response.json();
};

export default inviteEmployees;
