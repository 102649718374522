const Layout = ({ children }: any) => {
	return (
		<>
			{/* <Header /> */}
			{children}
			{/* <Footer /> */}
		</>
	);
};

export default Layout;
