import { Navigate, Outlet } from 'react-router-dom';

export interface RoutesI {
	token: string;
}

const PrivateRoute = ({ token }: RoutesI) => {
	return token ? <Outlet /> : <Navigate to={`/${window.location.search}`} />;
};

export default PrivateRoute;
