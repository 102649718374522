export interface CompanyInfoI {
	id: string;
	createdAt: string;
	updatedAt: string;
	companyEppConfigId: string;
	name: string;
	entityType: string;
	socialDenomination: string;
	NIF: string;
	incorporationDate: string;
	mercantileRegistryCity: string;
	tomo: number;
	folio: number;
	section: number;
	sheet: string;
	registration: number;
	cnaeCode: number;
	staffSize: number;
	fiscalAddress: string;
	postalCode: number;
	city: string;
	province: string;
	bankAccount: string;
}

const EMPTY_COMPANY_INFO: CompanyInfoI = {
	id: '',
	createdAt: '',
	updatedAt: '',
	companyEppConfigId: '',
	name: '',
	entityType: '',
	socialDenomination: '',
	NIF: '',
	incorporationDate: '',
	mercantileRegistryCity: '',
	tomo: 0,
	folio: 0,
	section: 0,
	sheet: '',
	registration: 0,
	cnaeCode: 0,
	staffSize: 0,
	fiscalAddress: '',
	postalCode: 0,
	city: '',
	province: '',
	bankAccount: ''
};

export default EMPTY_COMPANY_INFO;
