import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Text,
	Box,
	Flex,
	RadioGroup,
	Stack,
	Radio,
	Input
} from '@chakra-ui/react';
import { ContributionTypeEnum } from '../../../api/users/interfaces/ContributionData';
import ArcaSecondaryButton from '../../Buttons/ArcaSecondaryButton';
import ArcaPrimaryButton from '../../Buttons/ArcaPrimaryButton';

interface EditParticipantContributionModalProps {
	isOpen: boolean;
	onClose: () => void;
	isFreelance: boolean;
	contributionType: ContributionTypeEnum;
	setContributionType: (value: ContributionTypeEnum) => void;
	contributionFixedAmount: number | '' | undefined;
	handleChangeFixedContribution: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleSaveChangesEditFixedContribution: () => void;
	translate: (key: string) => string;
	companyEppConfig?: {
		employeeContributionRequired: boolean;
		employeeContributionMinimum: number;
	};
}

const EditParticipantContributionModal: React.FC<EditParticipantContributionModalProps> = ({
	isOpen,
	onClose,
	isFreelance,
	contributionType,
	setContributionType,
	contributionFixedAmount,
	handleChangeFixedContribution,
	handleSaveChangesEditFixedContribution,
	translate,
	companyEppConfig
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size="3xl">
			<ModalOverlay />
			<ModalContent p="20px">
				<ModalHeader textAlign="center">
					{translate('user.myContributions.modalEditMyContribution')}
				</ModalHeader>
				<ModalBody>
					<Box>
						<Text whiteSpace="pre-line" mb="24px" fontSize="md" textAlign="justify">
							{isFreelance
								? translate('user.myContributions.modalCopyEditMyContributionFreelance')
								: translate('user.myContributions.modalCopyEditMyContribution')}
						</Text>
						<Text>{translate('user.myContributions.modalSelectContributionType')} </Text>
						<Flex alignItems="center">
							<RadioGroup
								value={contributionType}
								onChange={(value) => setContributionType(value as ContributionTypeEnum)}>
								<Stack direction="row">
									<Radio colorScheme="blue" value={ContributionTypeEnum.FIXED}>
										{translate('user.myContributions.modalGrossEuros')}
									</Radio>
								</Stack>
							</RadioGroup>
						</Flex>
					</Box>
					<Box mt="24px">
						<Text fontSize="md">{`${translate('user.myContributions.modalMonthlyContribution')} ${
							companyEppConfig?.employeeContributionRequired
								? translate('user.myContributions.modalMonthlyContributionMandatory') +
									companyEppConfig?.employeeContributionMinimum +
									'€'
								: translate('user.myContributions.modalMonthlyContributionOptional')
						}`}</Text>
					</Box>
					<Box position="relative">
						<Input
							type="number"
							min={0}
							max={99999}
							value={contributionFixedAmount}
							onChange={handleChangeFixedContribution}
						/>
						<Text
							position="absolute"
							fontSize={20}
							right="1rem"
							top="25%"
							transform="translateY(-50%)"
							pointerEvents="none">
							€
						</Text>
					</Box>
				</ModalBody>
				<ModalFooter display="flex" justifyContent="space-between">
					<ArcaSecondaryButton onClick={onClose}>
						{translate('user.myContributions.modalClose')}
					</ArcaSecondaryButton>
					<ArcaPrimaryButton
						isDisabled={
							companyEppConfig?.employeeContributionRequired
								? Number(contributionFixedAmount) <
									Number(companyEppConfig?.employeeContributionMinimum)
									? true
									: false
								: false
						}
						onClick={handleSaveChangesEditFixedContribution}>
						{translate('user.myContributions.modalSaveChanges')}
					</ArcaPrimaryButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default EditParticipantContributionModal;
