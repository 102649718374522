const updateUserPassword = async (
	token: string,
	userId: string,
	currentPassword: string,
	newPassword: string
) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_API}/users/update-password/${userId}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			body: JSON.stringify({ currentPassword, newPassword })
		}
	);

	return response.json();
};

export default updateUserPassword;
