import React, { useState, useEffect, useContext } from 'react';
import { Spinner, Text, useDisclosure } from '@chakra-ui/react';
import acceptUserInvitation from '../api/users/acceptUserInvitation';
import { AcceptUserInvitationSuccessI } from '../api/users/interfaces/AcceptUserInvitationData';
import GenericModal from './ChakraUi/Modals/GenericModal';
import { I18nContext } from '../context/translations/TranslationsContext.context';
import { ArcaContext } from '../context/ArcaContext.context';
import { useNavigate } from 'react-router';
import ProjectConfig from '../constants';

const UserInvitation = () => {
	const { setToken, setCompanyId, setUserId, setUserAccountType, setLanguage } =
		useContext(ArcaContext);
	const {
		state: { translate }
	} = useContext(I18nContext);
	const [loading, setLoading] = useState<boolean>(true);
	const [userInvitationData, setAcceptUserInvitation] = useState<AcceptUserInvitationSuccessI>();
	const { isOpen: isOpenGeneric, onOpen: onOpenGeneric, onClose: onCloseGeneric } = useDisclosure();
	const [messageAlert, setMessageAlert] = useState('');
	const [isError, setIsError] = useState(false);
	const navigate = useNavigate();

	const invitationToken = new URLSearchParams(window.location.search).get('token') || '';

	const handleLoginSuccess = (responseData: any) => {
		if (responseData.user) {
			const companyId = responseData.user.companyId;
			setCompanyId(companyId);
			localStorage.setItem('companyId', companyId);

			const userId = responseData.user.id;
			setUserId(userId);
			localStorage.setItem('userId', userId);

			const userAccountType = responseData.user.accountType;
			setUserAccountType(userAccountType);
			localStorage.setItem('userAccountType', userAccountType);

			const language = responseData.user.language;
			setLanguage(language);
			localStorage.setItem('language', language);
		}

		if (responseData.token) {
			localStorage.setItem('token', responseData.token);
			setToken(responseData.token);
		}
	};

	useEffect(() => {
		const acceptUserInvitationAsync = async () => {
			try {
				const res = await acceptUserInvitation(invitationToken);
				if (!res.success) {
					setLoading(false);

					if (res.message === 'INVALID_TOKEN') {
						setMessageAlert(translate('user.userInvitation.invitationIncorrectOrExpired'));
					} else {
						setMessageAlert(translate('user.userInvitation.genericError'));
					}
					onOpenGeneric();
					setIsError(true);
				} else {
					setMessageAlert(translate('user.userInvitation.success'));
					setAcceptUserInvitation(res.data);
					setLoading(false);
					setIsError(false);

					onOpenGeneric();

					// Auto LOGIN
					handleLoginSuccess(res.data);
					navigate('/dashboard/home');
				}
			} catch (error) {
				setLoading(false);
				console.error('Error accepting invitation:', error);
				setMessageAlert(translate('user.userInvitation.invitationIncorrectOrExpired'));
				onOpenGeneric();
				setIsError(true);
			}
		};
		acceptUserInvitationAsync();
	}, []);

	return (
		<div>
			<h1>{translate('user.acceptInvitation.title')}</h1>
			{loading ? (
				<Spinner
					thickness="16px"
					speed="0.65s"
					emptyColor={ProjectConfig.ARCA_COLORS.GREEN_400}
					color={ProjectConfig.ARCA_COLORS.GREEN_800}
					size="xl"
					textAlign="center"
					mt={10}
				/>
			) : (
				<div>
					<Text fontWeight="bold" fontSize="xl" mb={4} textAlign="center"></Text>
					<Text fontWeight="bold" fontSize="xl" mb={4} textAlign="center">
						{userInvitationData?.user}
					</Text>
					<GenericModal
						isOpen={isOpenGeneric}
						onClose={onCloseGeneric}
						message={messageAlert}
						isError={isError}
						useLink={isError}
						link="/"
					/>
				</div>
			)}
		</div>
	);
};

export default UserInvitation;
