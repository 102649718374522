const login = async (email: string, password: string) => {
	const response = await fetch(`${process.env.REACT_APP_BASE_API}/auth/login`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ email, password })
	});

	return response.json();
};

export default login;
