import {
	Box,
	Button,
	Flex,
	FormLabel,
	Heading,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	ListItem,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Radio,
	RadioGroup,
	Stack,
	Text,
	UnorderedList,
	useDisclosure
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { I18nContext } from '../context/translations/TranslationsContext.context';
import getCurrentUser from '../api/users/getCurrentUser';
import { CurrentUserI, UserGenderEnum } from '../api/users/interfaces/Users ';
import { ArcaContext } from '../context/ArcaContext.context';
import { useNavigate } from 'react-router';
import getCompanyInfoEppConfig from '../api/companies/getCompanyInfoEppConfig';
import ProjectConfig from '../constants';
import userCompleteOnboarding from '../api/users/userCompleteOnboarding';
import LoadingViewSpinner from './ChakraUi/Spinners/LoadingViewSpinner';
import { clearContextAndLocalStorage } from '../utils/clearLocalStorageAndContext';
import GenericToast, { ToastStatusEnum } from './ChakraUi/Toasts/GenericToast';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import GenericModal from './ChakraUi/Modals/GenericModal';

interface Props {
	// Define props here
}

const OnboardingUser: React.FC<Props> = (props) => {
	const navigate = useNavigate();
	const { userAccountType, token, companyId, userId, language } = useContext(ArcaContext);

	const {
		state: { translate }
	} = useContext(I18nContext);

	const [initialLoading, setInitialLoading] = useState<boolean>(true);
	const [companyEppInfo, setCompanyEppInfo] = useState<any>();
	const [currentUser, setCurrentUser] = useState<CurrentUserI>();
	const [userInfo, setUserInfo] = useState<{
		name: string;
		firstSurname: string;
		lastSurname: string;
		gender: string;
		nif: string;
		email: string;
		password: string;
		repeatPassword: string;
	}>({
		name: '',
		firstSurname: '',
		lastSurname: '',
		gender: '',
		nif: '',
		email: '',
		password: '',
		repeatPassword: ''
	});
	const [monthlyAmount, setMonthlyAmount] = useState<number | ''>(50);
	const [showToast, setShowToast] = useState<boolean>(false);
	const [toastInfo, setToastInfo] = useState<any>({
		title: '',
		description: '',
		status: ''
	});

	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showRepeatPassword, setShowRepeatPassword] = useState(false);
	const {
		isOpen: isOpenCheckModal,
		onOpen: onOpenCheckModal,
		onClose: onCloseCheckModal
	} = useDisclosure();

	useEffect(() => {
		const getCompanyInfoEppConfigAsync = async () => {
			const currentCompanyInfoEppConfig = await getCompanyInfoEppConfig(token, companyId);
			const currentCompanyInfoEppConfigData = currentCompanyInfoEppConfig.data;
			setCompanyEppInfo(currentCompanyInfoEppConfigData);
			setMonthlyAmount(
				currentCompanyInfoEppConfig.data?.companyEppConfig?.employeeContributionMinimum
			);
		};
		getCompanyInfoEppConfigAsync();

		const getCurrentUserAsync = async () => {
			const currentUser = await getCurrentUser(token);
			const currentUserData = currentUser.data as CurrentUserI;
			setCurrentUser(currentUserData);
			const { name, firstSurname, lastSurname, gender, nif, email } = currentUserData?.user;

			setUserInfo({
				name: name,
				firstSurname: firstSurname,
				lastSurname: lastSurname,
				gender: gender,
				nif: nif,
				email: email,
				password: '',
				repeatPassword: ''
			});
		};
		getCurrentUserAsync();
		setTimeout(() => {
			setInitialLoading(false);
		}, 1500);
	}, []);

	const {
		isOpen: isOpenJoiningProcess,
		onOpen: onOpenJoiningProcess,
		onClose: onCloseJoiningProcess
	} = useDisclosure();

	const handleChangeMonthlyAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value === '') {
			setMonthlyAmount('');
		} else {
			const numericValue = Math.min(Number(value), 99999);
			if (!isNaN(numericValue)) {
				setMonthlyAmount(numericValue);
			}
		}
	};

	const handleUserCompleteOnboarding = async () => {
		const response = await userCompleteOnboarding(
			token,
			monthlyAmount,
			userInfo?.gender,
			userInfo?.name,
			userInfo?.firstSurname,
			userInfo?.lastSurname,
			userInfo?.nif,
			userInfo?.email,
			userInfo?.password
		);

		if (response.success) {
			setToastInfo({
				title: `${translate('onboarding.employee.toastSuccessTitle')}`,
				description: `${translate('onboarding.employee.toastSuccessDescription')}`,
				status: ToastStatusEnum.INFO
			});
			setShowToast(true);

			setTimeout(() => {
				clearContextAndLocalStorage();
				navigate('/');
			}, 6000);

			return;
		} else {
			setToastInfo({
				title: `${translate('onboarding.employee.toastFailureTitle')}`,
				description: `${translate('onboarding.employee.toastFailureDescription')}`,
				status: ToastStatusEnum.ERROR
			});
			setShowToast(true);
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setUserInfo((prevUserInfo) => {
			if (prevUserInfo) {
				return {
					...prevUserInfo,
					[name]: value
				};
			}
			return {
				name: '',
				firstSurname: '',
				lastSurname: '',
				gender: '',
				nif: '',
				email: '',
				password: '',
				repeatPassword: ''
			};
		});
	};

	const handleGenderChange = (value: string) => {
		handleInputChange({ target: { name: 'gender', value } } as React.ChangeEvent<HTMLInputElement>);
	};

	const isFormValid = () => {
		return (
			userInfo?.name &&
			userInfo.firstSurname &&
			userInfo.lastSurname &&
			userInfo.nif &&
			userInfo.email &&
			userInfo.password &&
			userInfo.repeatPassword &&
			userInfo.password === userInfo.repeatPassword &&
			userInfo.gender
		);
	};

	const checkCancelOnboarding = () => {
		onOpenCheckModal();
	};

	const cancelOnboarding = () => {
		clearContextAndLocalStorage();
		navigate('/');
	};

	return (
		<Box
			display="flex"
			justifyContent="center"
			height="100vh"
			background={ProjectConfig.ARCA_COLORS.LIGHT_GREY_03}>
			<Box
				w="700px"
				p={4}
				background={ProjectConfig.ARCA_COLORS.WHITE}
				borderRadius="18px"
				margin="10px"
				m="auto">
				{initialLoading ? (
					<>
						<Box h="80dvh" display="flex" justifyContent="center" alignItems="center">
							<LoadingViewSpinner />
						</Box>
					</>
				) : (
					<>
						<Box display="flex" justifyContent="center">
							<img
								src="/imgs/LOGO_VERDE_2.svg"
								alt="Logo Arca"
								style={{ height: 'auto', width: '200px' }}
							/>
						</Box>
						<Heading fontSize="3xl" textAlign="center" mb="24px">
							{`${translate('onboarding.employee.welcome')} ${currentUser?.user?.name || currentUser?.user?.email}!`}
						</Heading>
						<Text fontSize="16px">
							<Text as="span" fontWeight="bold">
								{companyEppInfo?.company?.name}
							</Text>
							{` ${translate('onboarding.employee.intro')}`}
						</Text>
						<Box display="flex" justifyContent="center" mt="10px" mb="8px">
							<img
								src="/Foto_rendimiento.png"
								alt="Foto del rendimiento"
								style={{ height: 'auto', width: '480px' }}
							/>
						</Box>
						<Text fontSize="16px">{`${translate('onboarding.employee.conditions')}`}</Text>
						<UnorderedList>
							<ListItem mx="20px">
								{`${companyEppInfo?.company?.name} ${translate('onboarding.employee.condition1.1')}`}
								<Text as="span" fontWeight="bold">
									{companyEppInfo?.companyEppConfig?.employerContributionType === 'PERCENTAGE'
										? translate('onboarding.employee.condition1.2')
										: translate('onboarding.employee.condition1.3')}
									{companyEppInfo?.companyEppConfig?.employerContributionAmount}€.
								</Text>
							</ListItem>
							<ListItem mx="20px">
								{`${translate('onboarding.employee.condition2.1')}`}
								<Text as="span" fontWeight="bold">
									{companyEppInfo?.companyEppConfig?.contributionPeriodicity &&
										translate('onboarding.employee.condition2.2')}
									.
								</Text>
							</ListItem>
							<ListItem mx="20px">
								{`${translate('onboarding.employee.condition3.1')}`}
								<Text as="span" fontWeight="bold">
									{companyEppInfo?.companyEppConfig?.employeeContributionRequired === false
										? translate('onboarding.employee.condition3.2')
										: translate('onboarding.employee.condition3.3') +
											translate('onboarding.employee.condition3.4') +
											`${companyEppInfo?.companyEppConfig?.employeeContributionMinimum}` +
											translate('onboarding.employee.condition4.1')}
								</Text>
							</ListItem>
						</UnorderedList>
						<Box display="flex" justifyContent="center" my="8px">
							{/* FIXME */}
							<Button
								onClick={checkCancelOnboarding}
								color={ProjectConfig.ARCA_COLORS.BLACK}
								background="none"
								textDecoration="underline"
								_hover={{
									color: ProjectConfig.ARCA_COLORS.LIGHT_GREY_03,
									background: ProjectConfig.ARCA_COLORS.PINK_900,
									border: 'none'
								}}>
								{translate('onboarding.employee.rejection')}
							</Button>

							<Button
								onClick={onOpenJoiningProcess}
								color={ProjectConfig.ARCA_COLORS.BLACK}
								backgroundColor={ProjectConfig.ARCA_COLORS.PINK_200}
								_hover={{
									color: ProjectConfig.ARCA_COLORS.BLACK,
									background: ProjectConfig.ARCA_COLORS.GREEN_500,
									fontWeight: 'bold',
									fontSize: '17px'
								}}>
								{translate('onboarding.employee.acceptance')}
							</Button>

							<Modal
								isOpen={isOpenJoiningProcess}
								onClose={onCloseJoiningProcess}
								size="2xl"
								closeOnOverlayClick={false}>
								<ModalOverlay />
								<ModalContent p="20px" maxW="800px">
									<ModalHeader textAlign="center">
										{translate('onboarding.employee.modalWelcome1.1')}
										{currentUser?.user?.name || currentUser?.user?.email}! 🥳🎉
									</ModalHeader>
									<form>
										<ModalBody>
											<Box mb={5}>
												<Text mb={5} fontSize="lg" textAlign="justify">
													{translate('onboarding.employee.modalWelcome1.2')}
												</Text>
												<Box display="flex" justifyContent="center">
													<img
														src="/imgs/High_Five.png"
														alt="Compañeros celebrando"
														style={{ height: 'auto', width: '250px' }}
													/>
												</Box>
												<Text mb={2} fontSize="lg" textAlign="justify">
													{translate('onboarding.employee.modalWelcome1.4')}
												</Text>

												<FormLabel m="0px 5px -8px 10px" fontSize="14px">
													{translate('onboarding.employee.inputName')}
												</FormLabel>
												<Input
													name="name"
													type="text"
													value={userInfo?.name}
													onChange={handleInputChange}
													borderColor={
														userInfo.name
															? ProjectConfig.ARCA_COLORS.GREEN_500
															: ProjectConfig.ARCA_COLORS.RED_500
													}
													_focus={{ borderColor: ProjectConfig.ARCA_COLORS.GREEN_500 }}
												/>
												<FormLabel m="-5px 5px -8px 10px" fontSize="14px">
													{translate('onboarding.employee.inputFirstSurname')}
												</FormLabel>
												<Input
													name="firstSurname"
													type="text"
													value={userInfo?.firstSurname}
													onChange={handleInputChange}
													borderColor={
														userInfo.firstSurname
															? ProjectConfig.ARCA_COLORS.GREEN_500
															: ProjectConfig.ARCA_COLORS.RED_500
													}
													_focus={{ borderColor: ProjectConfig.ARCA_COLORS.GREEN_500 }}
												/>
												<FormLabel m="-5px 5px -8px 10px" fontSize="14px">
													{translate('onboarding.employee.inputLastSurname')}
												</FormLabel>
												<Input
													name="lastSurname"
													type="text"
													value={userInfo?.lastSurname}
													onChange={handleInputChange}
													borderColor={
														userInfo.lastSurname
															? ProjectConfig.ARCA_COLORS.GREEN_500
															: ProjectConfig.ARCA_COLORS.RED_500
													}
													_focus={{ borderColor: ProjectConfig.ARCA_COLORS.GREEN_500 }}
												/>
												<FormLabel m="-5px 5px 0px 10px" fontSize="14px">
													{translate('onboarding.employee.inputGender')}
												</FormLabel>
												<Flex alignItems="center">
													<RadioGroup
														defaultValue={userInfo?.gender}
														value={userInfo?.gender}
														onChange={handleGenderChange}
														mb={1}
														name="gender">
														<Stack direction="row">
															<Radio colorScheme="green" type="gender" value={UserGenderEnum.MEN}>
																{translate('onboarding.employee.inputGender1')}
															</Radio>
															<Radio colorScheme="green" type="gender" value={UserGenderEnum.WOMEN}>
																{translate('onboarding.employee.inputGender2')}
															</Radio>
														</Stack>
													</RadioGroup>
												</Flex>
												<FormLabel m="0px 5px -8px 10px" fontSize="14px">
													{translate('onboarding.employee.inputNIF')}
												</FormLabel>
												<Input
													name="nif"
													type="text"
													value={userInfo?.nif}
													onChange={handleInputChange}
													borderColor={
														userInfo.nif
															? ProjectConfig.ARCA_COLORS.GREEN_500
															: ProjectConfig.ARCA_COLORS.RED_500
													}
													_focus={{ borderColor: ProjectConfig.ARCA_COLORS.GREEN_500 }}
												/>
												<FormLabel m="-5px 5px -8px 10px" fontSize="14px">
													{translate('onboarding.employee.inputEmail')}
												</FormLabel>
												<Input
													name="email"
													type="email"
													value={userInfo?.email}
													isReadOnly={true}
													borderColor={
														userInfo.email
															? ProjectConfig.ARCA_COLORS.GREEN_500
															: ProjectConfig.ARCA_COLORS.RED_500
													}
													_focus={{ borderColor: ProjectConfig.ARCA_COLORS.GREEN_500 }}
												/>
												<FormLabel m="-5px 5px -8px 10px" fontSize="14px">
													{translate('onboarding.employee.inputNewPassword')}
												</FormLabel>
												<InputGroup>
													<Input
														name="password"
														type={showNewPassword ? 'text' : 'password'}
														value={userInfo?.password}
														onChange={handleInputChange}
														borderColor={
															userInfo.password
																? ProjectConfig.ARCA_COLORS.GREEN_500
																: ProjectConfig.ARCA_COLORS.RED_500
														}
														_focus={{ borderColor: ProjectConfig.ARCA_COLORS.GREEN_500 }}
													/>
													<InputRightElement width="3rem" top="10px">
														<IconButton
															aria-label={
																showNewPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'
															}
															icon={showNewPassword ? <ViewOffIcon /> : <ViewIcon />}
															onClick={() => setShowNewPassword(!showNewPassword)}
															variant="ghost"
															color={ProjectConfig.ARCA_COLORS.PINK_400}
															_hover={{ bg: 'transparent' }}
														/>
													</InputRightElement>
												</InputGroup>
												<InputGroup mt="-8px">
													<Input
														mb={4}
														name="repeatPassword"
														type={showRepeatPassword ? 'text' : 'password'}
														value={userInfo?.repeatPassword}
														onChange={handleInputChange}
														borderColor={
															userInfo.repeatPassword
																? ProjectConfig.ARCA_COLORS.GREEN_500
																: ProjectConfig.ARCA_COLORS.RED_500
														}
														placeholder={translate(
															'onboarding.employee.placeholderRepeatNewPassword'
														)}
														_focus={{ borderColor: ProjectConfig.ARCA_COLORS.GREEN_500 }}
													/>
													<InputRightElement width="3rem" top="10px">
														<IconButton
															aria-label={
																showRepeatPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'
															}
															icon={showRepeatPassword ? <ViewOffIcon /> : <ViewIcon />}
															onClick={() => setShowRepeatPassword(!showRepeatPassword)}
															variant="ghost"
															color={ProjectConfig.ARCA_COLORS.PINK_400}
															_hover={{ bg: 'transparent' }}
														/>
													</InputRightElement>
												</InputGroup>
												<Text fontSize="lg" textAlign="justify">
													{translate('onboarding.employee.modalWelcome1.3')}
												</Text>
												<Text>
													{translate('user.myContributions.modalSelectContributionType')}{' '}
												</Text>
												<Flex alignItems="center">
													<RadioGroup defaultValue="1">
														<Stack direction="row">
															<Radio colorScheme="green" value="1">
																{translate('user.myContributions.modalGrossEuros')}
															</Radio>
														</Stack>
													</RadioGroup>
												</Flex>
											</Box>
											<Box>
												<Text fontSize="lg">{`${translate('user.myContributions.modalMonthlyContribution')} ${
													companyEppInfo?.companyEppConfig?.employeeContributionRequired
														? translate('user.myContributions.modalMonthlyContributionMandatory') +
															companyEppInfo?.companyEppConfig?.employeeContributionMinimum +
															'€'
														: translate('user.myContributions.modalMonthlyContributionOptional')
												}`}</Text>
											</Box>
											<Box position="relative">
												<Input
													type="number"
													min={
														companyEppInfo.data?.companyEppConfig?.employeeContributionMinimum ?? 1
													}
													max={9999}
													value={Number(monthlyAmount) > 9999 ? 9999 : monthlyAmount}
													onChange={handleChangeMonthlyAmount}
													borderColor={
														monthlyAmount
															? ProjectConfig.ARCA_COLORS.GREEN_500
															: ProjectConfig.ARCA_COLORS.RED_500
													}
													_focus={{ borderColor: ProjectConfig.ARCA_COLORS.GREEN_500 }}
													pr="2rem"
												/>
												<Text
													position="absolute"
													fontSize={20}
													right="1rem"
													top="15%"
													transform="translateY(-50%)"
													pointerEvents="none">
													€
												</Text>
												<Text mt="-8px" fontSize="sm">
													{translate('user.myContributions.modalMonthlyContributionAdvice')}
												</Text>
											</Box>
										</ModalBody>
										<ModalFooter>
											<Button
												onClick={onCloseJoiningProcess}
												color={ProjectConfig.ARCA_COLORS.BLACK}
												background="none"
												textDecoration="underline"
												_hover={{
													color: ProjectConfig.ARCA_COLORS.LIGHT_GREY_03,
													background: ProjectConfig.ARCA_COLORS.PINK_900,
													border: 'none'
												}}>
												{translate('onboarding.employee.modalOnClose')}
											</Button>
											<Button
												onClick={handleUserCompleteOnboarding}
												color={ProjectConfig.ARCA_COLORS.BLACK}
												width="90%"
												ml="24px"
												backgroundColor={ProjectConfig.ARCA_COLORS.PINK_200}
												_hover={{
													color: ProjectConfig.ARCA_COLORS.BLACK,
													background: ProjectConfig.ARCA_COLORS.GREEN_500,
													fontWeight: 'bold',
													fontSize: '17px'
												}}
												isDisabled={
													!isFormValid() ||
													monthlyAmount === '' ||
													Number(monthlyAmount) <
														Number(companyEppInfo?.companyEppConfig?.employeeContributionMinimum) ||
													monthlyAmount === 0
												}>
												{translate('onboarding.employee.modalAmountConfirmation')}
											</Button>
										</ModalFooter>
									</form>
								</ModalContent>
							</Modal>
						</Box>
					</>
				)}
				{showToast && (
					<GenericToast
						title={toastInfo?.title}
						description={toastInfo?.description}
						status={toastInfo?.status}
						duration={6000}
						showToast={showToast}
					/>
				)}
			</Box>
			<GenericModal
				modalTitle={'¿Estás seguro?'}
				isOpen={isOpenCheckModal}
				onClose={onCloseCheckModal}
				onAction={cancelOnboarding}
				onActionNotDisabled={true}
				message="¿Estás seguro de que no estás interesado? Si confirmas que no estás interesado, registraremos tu voluntad y no podrás beneficiarte del plan de pensiones."
				isError={false}
				closeModalButton={true}
			/>
		</Box>
	);
};

export default OnboardingUser;
