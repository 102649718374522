import React, { useState } from 'react';
import {
	Box,
	Text,
	Flex,
	VStack,
	Grid,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormLabel,
	Input,
	Select,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	Image,
	Step,
	StepIndicator,
	StepTitle,
	StepSeparator,
	Stepper,
	StepStatus,
	StepIcon,
	StepNumber
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { formatArcaNumber } from '../../../utils/formatArcaNumbers';
import ProjectConfig from '../../../constants';
import ArcaPrimaryButton from '../../../components/Buttons/ArcaPrimaryButton';
import ArcaSecondaryButton from '../../../components/Buttons/ArcaSecondaryButton';
import CustomProgress from '../../../components/Progress/CustomProgress';

// Definimos un array de objetivos simulando que vienen del backend
const initialObjetivos: any[] = [
	{
		categoria: 'Estudios',
		salarioBrutoAnual: 0,
		precioCasa: 0,
		comprasTipo: '',
		progreso: 0,
		meta: 0,
		progresoTexto: '',
		dineroActual: 0,
		estado: 'SIN EMPEZAR'
	},
	{
		categoria: 'Compra vivienda',
		salarioBrutoAnual: 0,
		precioCasa: 0,
		comprasTipo: 'individual',
		progreso: 0,
		meta: 0,
		progresoTexto: '',
		dineroActual: 0,
		estado: 'SIN EMPEZAR'
	},
	{
		categoria: 'Jubilación',
		salarioBrutoAnual: 0,
		precioCasa: 0,
		comprasTipo: '',
		progreso: 0,
		meta: 0,
		progresoTexto: '',
		dineroActual: 0,
		estado: 'SIN EMPEZAR'
	}
];

const UserDashboardMultiproduct: React.FC = () => {
	const [objetivos, setObjetivos] = useState(initialObjetivos);
	const [isOpen, setIsOpen] = useState(false);
	const [nuevoObjetivo, setNuevoObjetivo] = useState({
		categoria: '',
		salarioBrutoAnual: 0,
		precioCasa: 0,
		precioMaster: 0,
		comprasTipo: '',
		nombreObjetivo: '',
		precioObjetivo: 0
	});
	const [salarioBrutoAnual, setSalarioBrutoAnual] = useState(0);
	const [selectedObjetivo, setSelectedObjetivo] = useState<string>('');
	const [isSelectOpen, setIsSelectOpen] = useState(false);
	const [isContractModalOpen, setIsContractModalOpen] = useState(false);
	const [selectedProducto, setSelectedProducto] = useState<string>('');
	const {
		isOpen: isSalaryModalOpen,
		onOpen: onOpenSalaryModal,
		onClose: onCloseSalaryModal
	} = useDisclosure();
	const [cantidadSolicitar, setCantidadSolicitar] = useState(0);
	const [activeStep, setActiveStep] = useState(0); // Nueva variable de estado para el paso activo

	const steps = [
		{ title: 'Crear un objetivo' },
		{ title: 'Elegir producto de ahorro' },
		{ title: 'Realizar tu primera aportación mensual' }
	];

	const [totalAmount, setTotalAmount] = useState(0);

	const handleAddObjetivo = () => {
		// Verificar si ya existe un objetivo con la misma categoría
		const existeObjetivoIndex = objetivos.findIndex(
			(objetivo) => objetivo.categoria === nuevoObjetivo.categoria
		);
		if (existeObjetivoIndex !== -1) {
			// Si existe, actualizamos el objetivo
			const updatedObjetivos = [...objetivos];
			const meta =
				nuevoObjetivo.categoria === 'Estudios'
					? nuevoObjetivo.precioMaster
					: nuevoObjetivo.precioObjetivo;
			updatedObjetivos[existeObjetivoIndex] = {
				...nuevoObjetivo,
				progreso: updatedObjetivos[existeObjetivoIndex].progreso,
				meta,
				progresoTexto: updatedObjetivos[existeObjetivoIndex].progresoTexto,
				dineroActual: updatedObjetivos[existeObjetivoIndex].dineroActual,
				estado: 'SIN EMPEZAR'
			};
			setObjetivos(updatedObjetivos);
		} else {
			// Si no existe, creamos uno nuevo
			const meta =
				nuevoObjetivo.categoria === 'Estudios'
					? nuevoObjetivo.precioMaster
					: nuevoObjetivo.precioObjetivo;
			setObjetivos([
				...objetivos,
				{
					...nuevoObjetivo,
					progreso: 0,
					meta,
					progresoTexto: '',
					dineroActual: 0,
					estado: 'SIN EMPEZAR'
				}
			]);
		}
		setNuevoObjetivo({
			categoria: '',
			salarioBrutoAnual: 0,
			precioCasa: 0,
			precioMaster: 0,
			precioObjetivo: 0,
			comprasTipo: '',
			nombreObjetivo: ''
		});
		setSalarioBrutoAnual(0);
		setIsOpen(false);
		setActiveStep(1); // Marca el primer paso como completado
	};

	const handleAddProducto = () => {
		if (objetivos.length === 0) {
			alert('Primero crea un objetivo antes de añadir un nuevo producto.'); // Mensaje si no hay objetivos
			return;
		}
		setIsSelectOpen(true);
	};

	// Nueva función para verificar si existe un objetivo de colchón de emergencia
	const hasEmergencyFundObjective = () => {
		return objetivos.some((objetivo) => objetivo.categoria === 'Estudios');
	};

	return (
		<VStack spacing={6} p={4} bg={ProjectConfig.ARCA_COLORS.LIGHT_GREY_02} borderRadius="md">
			{/* Primera fila: Gráfica y Ahorro e inversión */}
			<Grid templateColumns="repeat(2, 1fr)" gap={6} width="100%">
				<Box p={4} bg="white" borderRadius="24px" boxShadow="md">
					<Text fontSize="lg" fontWeight="bold" color={ProjectConfig.ARCA_COLORS.PINK_500}>
						En progreso
					</Text>
					{objetivos.length === 0 ? (
						<Text fontSize="3xl" fontWeight="bold" mt={4}>
							Empieza por añadir tus objetivos
						</Text>
					) : (
						<>
							<Image
								src="/multiproduct-hardcoded-graphics.png"
								alt="Foto del rendimiento"
								style={{ height: 'auto', width: '480px' }}
							/>
							<Text fontSize="3xl" fontWeight="bold" mt={4}>
								€{formatArcaNumber(totalAmount)}
							</Text>
							<Box
								p={2}
								borderRadius="md"
								boxShadow="md"
								display="flex"
								alignItems="center"
								justifyContent="center">
								<Text fontSize="sm" fontWeight="bold" color={ProjectConfig.ARCA_COLORS.RED_500}>
									⭐️ ¡Podrías estar obteniendo un 2,5% más de rentabilidad, añade un nuevo
									producto!
								</Text>
							</Box>
						</>
					)}
				</Box>

				{/* Caja grande que contiene Ahorro e inversión y Progreso */}
				<Box
					p={6}
					bg={ProjectConfig.ARCA_COLORS.WHITE}
					borderRadius="24px"
					boxShadow="lg"
					border="1px solid #E2E8F0">
					<Text fontSize="lg" fontWeight="bold" color={ProjectConfig.ARCA_COLORS.PINK_500}>
						Ahorro e inversión
					</Text>
					<Flex mt={4} justifyContent="space-between" alignItems="center">
						<ArcaPrimaryButton onClick={handleAddProducto} borderRadius="full" px={6}>
							Añadir nuevo producto
						</ArcaPrimaryButton>
						<ArcaSecondaryButton onClick={() => {}}>Realizar nueva aportación</ArcaSecondaryButton>
					</Flex>
					<Flex mt={4} justifyContent="space-between">
						<ArcaPrimaryButton
							onClick={() => {
								if (!hasEmergencyFundObjective()) {
									alert('Debes crear un objetivo de "Estudios" antes de anticipar salario.'); // Mensaje si no hay objetivo
									return;
								}
								onOpenSalaryModal(); // Abre el modal de anticipar salario
							}}>
							Anticipar salario
						</ArcaPrimaryButton>
					</Flex>

					{/* Nueva subcaja para el stepper */}
					<Box p={4} bg="white" borderRadius="24px" boxShadow="md" mt={4}>
						<Text fontSize="lg" fontWeight="bold" color={ProjectConfig.ARCA_COLORS.PINK_500}>
							Progreso
						</Text>
						<Stepper index={activeStep} colorScheme="pink" mt={4} orientation="vertical">
							{steps.map((step, index) => (
								<Step key={index}>
									<StepIndicator>
										<StepStatus
											complete={<StepIcon />}
											incomplete={<StepNumber />}
											active={<StepNumber />}
										/>
									</StepIndicator>

									<Box flexShrink="0">
										<StepTitle>{step.title}</StepTitle>
									</Box>

									<StepSeparator />
								</Step>
							))}
						</Stepper>
					</Box>
				</Box>
			</Grid>

			{/* Segunda fila: Mis Objetivos y Finanzas */}
			<Grid templateColumns="repeat(2, 1fr)" gap={6} width="100%">
				<Box p={4} bg="white" borderRadius="24px" boxShadow="md">
					<Text fontSize="lg" fontWeight="bold" color={ProjectConfig.ARCA_COLORS.PINK_500}>
						Mis Objetivos
					</Text>
					<Text fontSize="md" fontWeight="bold" mt={2}>
						Metas de ahorro
					</Text>
					<Box mt={2}>
						{/* Renderizamos los objetivos usando .map */}
						{objetivos.map((objetivo, index) => (
							<Box key={index} mt={4}>
								{/* Título en negrita con el nombre de la categoría y el icono */}
								<Text
									fontSize="20px"
									fontWeight="bold"
									color={ProjectConfig.ARCA_COLORS.BLACK}
									marginBottom={2}>
									{objetivo.categoria}
									{objetivo.categoria === 'Compra vivienda'
										? '  🏠'
										: objetivo.categoria === 'Estudios'
											? '  📚'
											: objetivo.categoria === 'Jubilación'
												? '  👴'
												: ''}
								</Text>
								{/* Cambiado para usar los valores de cada objetivo */}
								<CustomProgress value={objetivo.dineroActual} max={objetivo.meta} />
								<Flex justify="space-between">
									<Text>{objetivo.estado}</Text>
									<Text color="gray.500">
										{formatArcaNumber(objetivo.dineroActual)} /{' '}
										{objetivo.categoria === 'Compra vivienda'
											? formatArcaNumber(
													(objetivo.precioCasa * 0.2) / (objetivo.comprasTipo === 'pareja' ? 2 : 1)
												)
											: formatArcaNumber(objetivo.meta)}{' '}
										€ (
										{objetivo.meta > 0
											? Math.floor(
													objetivo.dineroActual /
														(objetivo.categoria === 'Compra vivienda'
															? (objetivo.precioCasa * 0.2) /
																(objetivo.comprasTipo === 'pareja' ? 2 : 1)
															: Number(objetivo.meta))
												)
											: 0}{' '}
										meses)
									</Text>
								</Flex>
							</Box>
						))}
					</Box>
					<ArcaPrimaryButton
						mt={4}
						colorScheme={ProjectConfig.ARCA_COLORS.PINK_300}
						onClick={() => setIsOpen(true)}>
						Añadir objetivo
					</ArcaPrimaryButton>
				</Box>

				<Box p={4} bg="white" borderRadius="24px" boxShadow="md">
					<Text fontSize="lg" fontWeight="bold" color={ProjectConfig.ARCA_COLORS.PINK_500}>
						Finanzas
					</Text>
					<Text mt={2}>
						Empieza a usar tu plan personal que hemos preparado para ti, para mejorar tus finanzas
						personales.
					</Text>
					<a
						href="https://arcadigital.app/version-test/simulator_savings"
						target="_blank"
						rel="noopener noreferrer">
						<ArcaPrimaryButton mt={4} rightIcon={<span>→</span>}>
							Nueva simulación
						</ArcaPrimaryButton>
					</a>
				</Box>
			</Grid>

			{/* Modal para añadir nuevo objetivo */}
			<Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader color={ProjectConfig.ARCA_COLORS.PINK_500}>
						Añadir Nuevo Objetivo
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<FormControl mt={4}>
							<FormLabel>Categoría del objetivo</FormLabel>
							<Select
								value={nuevoObjetivo.categoria}
								onChange={(e) => {
									const selectedCategory = e.target.value;
									setNuevoObjetivo({
										...nuevoObjetivo,
										categoria: selectedCategory,
										precioMaster: 0
									});
									if (selectedCategory === 'Estudios') {
										setSalarioBrutoAnual(0);
									}
								}}>
								<option value="">Selecciona una categoría</option>
								<option value="Estudios">Estudios</option>
								<option value="Compra vivienda">Compra vivienda</option>
								<option value="Jubilación">Jubilación</option>
								<option value="Otro objetivo">Otro objetivo</option>
							</Select>
						</FormControl>

						{nuevoObjetivo.categoria === 'Estudios' && (
							<FormControl mt={4}>
								<FormLabel>Precio tus estudios en €</FormLabel>
								<Input
									type="number"
									value={nuevoObjetivo.precioMaster}
									onChange={(e) =>
										setNuevoObjetivo({ ...nuevoObjetivo, precioMaster: Number(e.target.value) })
									}
								/>
							</FormControl>
						)}
						{nuevoObjetivo.categoria === 'Compra vivienda' && (
							<Box mt={4}>
								<FormControl>
									<FormLabel>Salario bruto anual en €</FormLabel>
									<Input
										type="number"
										value={nuevoObjetivo.salarioBrutoAnual || ''}
										onChange={(e) =>
											setNuevoObjetivo({
												...nuevoObjetivo,
												salarioBrutoAnual: Number(e.target.value)
											})
										}
									/>
								</FormControl>
								<FormControl mt={4}>
									<FormLabel>Precio de la casa en €</FormLabel>
									<Input
										type="number"
										value={nuevoObjetivo.precioCasa || ''}
										onChange={(e) =>
											setNuevoObjetivo({ ...nuevoObjetivo, precioCasa: Number(e.target.value) })
										}
									/>
									<Text fontSize="sm" color="gray.400">
										Tu objetivo será la entrada necesaria para adquirir la vivienda, un 20% del
										precio total de la vivienda
									</Text>
								</FormControl>
								<FormControl mt={4}>
									<FormLabel>Compras en pareja o individualmente</FormLabel>
									<Select
										value={nuevoObjetivo.comprasTipo || ''}
										onChange={(e) =>
											setNuevoObjetivo({ ...nuevoObjetivo, comprasTipo: e.target.value })
										}>
										<option value="">Selecciona una opción</option>
										<option value="pareja">Pareja</option>
										<option value="individual">Individualmente</option>
									</Select>
								</FormControl>
							</Box>
						)}
						{nuevoObjetivo.categoria === 'Otro objetivo' && (
							<Box mt={4}>
								<FormControl isRequired>
									<FormLabel>Nombre del objetivo</FormLabel>
									<Input
										type="text"
										value={nuevoObjetivo.nombreObjetivo || ''}
										onChange={(e) =>
											setNuevoObjetivo({ ...nuevoObjetivo, nombreObjetivo: e.target.value })
										}
									/>
								</FormControl>
								<FormControl isRequired mt={4}>
									<FormLabel>Precio del objetivo en €</FormLabel>
									<Input
										type="number"
										value={nuevoObjetivo.precioObjetivo || ''}
										onChange={(e) =>
											setNuevoObjetivo({ ...nuevoObjetivo, precioObjetivo: Number(e.target.value) })
										}
									/>
								</FormControl>
							</Box>
						)}
						<ArcaPrimaryButton mt={4} onClick={handleAddObjetivo}>
							Agregar Objetivo
						</ArcaPrimaryButton>
					</ModalBody>
				</ModalContent>
			</Modal>

			{/* Modal para seleccionar un objetivo existente */}
			<Modal isOpen={isSelectOpen} onClose={() => setIsSelectOpen(false)}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader color={ProjectConfig.ARCA_COLORS.PINK_500}>
						Comienza a ahorrar para tus objetivos
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text fontSize="lg" fontWeight="bold">
							Selecciona un objetivo:
						</Text>
						<Select value={selectedObjetivo} onChange={(e) => setSelectedObjetivo(e.target.value)}>
							<option value="">Selecciona un objetivo</option>
							{objetivos.map((objetivo, index) => (
								<option key={index} value={objetivo.categoria}>
									{objetivo.categoria}
								</option>
							))}
						</Select>
						{/* Texto que varía según el objetivo seleccionado */}
						<Text fontSize="sm" color="gray.400">
							{selectedObjetivo === 'Estudios'
								? 'Ahorra para invertir en tus estudios.'
								: selectedObjetivo === 'Compra vivienda'
									? 'Ahorra un 20% del precio de la vivienda para tu entrada.'
									: selectedObjetivo === 'Jubilación'
										? 'Ahorra para que no te falte dinero cuando te jubiles.'
										: selectedObjetivo === 'Otro objetivo'
											? 'Ahorra para tu objetivo personal.'
											: ''}{' '}
						</Text>

						{/* Añadir iconos con textos */}
						{selectedObjetivo && ( // Solo mostrar si hay un objetivo seleccionado
							<Flex mt={4} justifyContent="space-between">
								<Box
									textAlign="center"
									border={
										selectedProducto === 'Seguro de ahorro'
											? '2px solid darkgreen'
											: '1px solid lightgray'
									}
									borderRadius="md"
									p={2}
									cursor="pointer"
									onClick={() => setSelectedProducto('Seguro de ahorro')}>
									<Text>💰</Text> {/* Icono de seguro de ahorro */}
									<Text fontSize="sm">Seguro de ahorro</Text>
								</Box>
								<Box
									textAlign="center"
									border={
										selectedProducto === 'Plan de pensiones'
											? '2px solid darkgreen'
											: '1px solid lightgray'
									}
									borderRadius="md"
									p={2}
									cursor="pointer"
									onClick={() => setSelectedProducto('Plan de pensiones')}>
									<Text>📈</Text> {/* Icono de plan de pensiones */}
									<Text fontSize="sm">Plan de pensiones</Text>
								</Box>
							</Flex>
						)}

						<ArcaPrimaryButton
							mt={4}
							colorScheme={ProjectConfig.ARCA_COLORS.PINK_300}
							onClick={() => {
								if (!selectedObjetivo || !selectedProducto) {
									alert('Debes seleccionar un objetivo y un producto primero.');
									return;
								}
								setIsSelectOpen(false);
								setIsContractModalOpen(true); // Abre el modal de contratación
							}}>
							Obtener más información
						</ArcaPrimaryButton>
					</ModalBody>
				</ModalContent>
			</Modal>

			{/* Modal para contratar un producto */}
			<Modal isOpen={isContractModalOpen} onClose={() => setIsContractModalOpen(false)}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader color={ProjectConfig.ARCA_COLORS.PINK_500}>
						Contratar un {selectedProducto}
					</ModalHeader>
					<Text fontSize="sm" textAlign="center">
						Objetivo seleccionado: {selectedObjetivo}
					</Text>{' '}
					{/* Muestra el objetivo seleccionado */}
					<ModalCloseButton />
					<ModalBody>
						<Text textAlign="center">Detalles para contratar el producto: {selectedProducto}</Text>
						{/* Aquí puedes añadir más detalles o formularios para la contratación */}
						<ArcaPrimaryButton mt={4} onClick={() => setIsContractModalOpen(false)}>
							COMENZAR CONTRATACIÓN
						</ArcaPrimaryButton>
					</ModalBody>
				</ModalContent>
			</Modal>

			{/* Modal para anticipar salario */}
			<Modal isOpen={isSalaryModalOpen} onClose={onCloseSalaryModal}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader color={ProjectConfig.ARCA_COLORS.PINK_500}>Anticipar Salario</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>
							A partir del día 15 podrás realizar el anticipo de tu salario. Lo recibirás de manera
							instantánea si tu banco tiene SEPA inmediata
						</Text>
						{/* Campo para mostrar el salario bruto anual */}
						<FormControl mt={4}>
							<FormLabel>Salario Bruto Anual (€)</FormLabel>
							<Input
								type="number"
								value={
									nuevoObjetivo.salarioBrutoAnual ||
									(hasEmergencyFundObjective()
										? objetivos.find((obj) => obj.categoria === 'Estudios')?.salarioBrutoAnual || 0
										: 0)
								}
								isReadOnly
							/>
						</FormControl>
						{/* Campo para indicar la cantidad a solicitar */}
						<FormControl mt={4}>
							<FormLabel>Cantidad a solicitar</FormLabel>
							<Flex alignItems="center">
								<Input
									type="number"
									value={cantidadSolicitar} // Nueva variable de estado para la cantidad
									readOnly
									width="80px"
									textAlign="center"
								/>
								<Slider
									aria-label="slider-ex-1"
									defaultValue={0}
									min={0}
									max={350}
									onChange={(val) => setCantidadSolicitar(val)} // Actualiza la cantidad al mover el slider
									mx={4}>
									<SliderTrack>
										<SliderFilledTrack />
									</SliderTrack>
									<SliderThumb />
								</Slider>
							</Flex>
						</FormControl>
						{/* Input para el IBAN */}
						<FormControl mt={4}>
							<FormLabel>IBAN</FormLabel>
							<Input type="text" placeholder="Introduce tu IBAN" />
							<FormLabel>SWIFT</FormLabel>
							<Input type="text" placeholder="Introduce tu SWIFT" />
						</FormControl>

						{/* Botón para anticipar salario */}
						<ArcaSecondaryButton
							mt={4}
							color={ProjectConfig.ARCA_COLORS.PINK_300}
							onClick={() => {
								/* Lógica para anticipar salario */
							}}>
							ANTICIPAR SALARIO
						</ArcaSecondaryButton>
					</ModalBody>
				</ModalContent>
			</Modal>
		</VStack>
	);
};

export default UserDashboardMultiproduct;
