import React from 'react';
import { Flex, Box, Text, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
	return (
		<Flex justify="center" align="center" h="100vh">
			<Box textAlign="center">
				<Text fontSize="3xl" fontWeight="bold" mb={4}>
					Página no encontrada
				</Text>
				<Text mb={6}>Lo sentimos, la página que estás buscando no existe.</Text>
				<Button colorScheme="blue" as={Link} to="/">
					Volver al inicio
				</Button>
			</Box>
		</Flex>
	);
};

export default NotFoundPage;
