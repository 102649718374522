import { Spinner } from '@chakra-ui/react';
import ProjectConfig from '../../../constants';

const LoadingViewSpinner: React.FC = () => {
	return (
		<Spinner
			thickness="50px"
			speed="1.5s"
			emptyColor={ProjectConfig.ARCA_COLORS.GREEN_400}
			color={ProjectConfig.ARCA_COLORS.GREEN_800}
			height="300px"
			width="300px"
		/>
	);
};

export default LoadingViewSpinner;
