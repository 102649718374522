const getInvoices = async (token: string, companyId: string) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_API}/google-cloud-platform/document-urls/${companyId}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		}
	);

	return response.json();
};

export default getInvoices;
