import React, { ReactNode, useState } from 'react';

interface ContextProps {
	children: React.ReactElement;
}

interface ArcaContextI {
	token: string;
	setToken: (value: string) => void;
	userId: string;
	setUserId: (value: string) => void;
	companyId: string;
	setCompanyId: (value: string) => void;
	userAccountType: string;
	setUserAccountType: (value: string) => void;
	language: string;
	setLanguage: (value: string) => void;
}

export const ArcaContext = React.createContext({} as ArcaContextI);

export const ArcaProvider = ({ children }: ContextProps) => {
	const hasTokenInLocalStorage = localStorage.getItem('token');
	const hasCompanyIdInLocalStorage = localStorage.getItem('companyId');
	const hasUserIdInLocalStorage = localStorage.getItem('userId');
	const hasUserAccountTypeInLocalStorage = localStorage.getItem('userAccountType');
	const hasLanguageInLocalStorage = localStorage.getItem('language');
	const [token, setToken] = useState<string>(hasTokenInLocalStorage || '');
	const [userId, setUserId] = useState<string>(hasUserIdInLocalStorage || '');
	const [companyId, setCompanyId] = useState<string>(hasCompanyIdInLocalStorage || '');
	const [userAccountType, setUserAccountType] = useState<string>(
		hasUserAccountTypeInLocalStorage || ''
	);
	const [language, setLanguage] = useState<string>(hasLanguageInLocalStorage || 'es');

	const values = {
		companyId,
		setCompanyId,
		userId,
		setUserId,
		token,
		setToken,
		userAccountType,
		setUserAccountType,
		language,
		setLanguage
	};

	return (
		<ArcaContext.Provider value={values}>{children as unknown as ReactNode}</ArcaContext.Provider>
	);
};
