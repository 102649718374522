const updateUserLanguage = async (token: string, language: string) => {
	const response = await fetch(`${process.env.REACT_APP_BASE_API}/users/update-language`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify({ language })
	});

	return response.json();
};

export default updateUserLanguage;
