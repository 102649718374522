import { Box, Text, Collapse, Button, VStack } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { I18nContext } from '../../../context/translations/TranslationsContext.context';
import SectionTitle from '../../../components/Titles/SectionTitle';
import getPortfolioLines, { Portfolio } from '../../../api/calculators/getPortfolioLines';
import { ArcaContext } from '../../../context/ArcaContext.context';
import ProjectConfig from '../../../constants';
import DocumentDownload from '../../../components/Documents/DocumentDownload';
import getCompanyInfoEppConfig from '../../../api/companies/getCompanyInfoEppConfig';
import EMPTY_COMPANY_INFO, { CompanyInfoI } from '../../../api/companies/interfaces/CompanyInfo';
import EMPTY_COMPANY_EPP_CONFIG, {
	CompanyEppConfigI
} from '../../../api/companies/interfaces/CompanyEppConfig';
import LoadingViewSpinner from '../../../components/ChakraUi/Spinners/LoadingViewSpinner';
import EMPTY_CURRENT_USER, { CurrentUserI } from '../../../api/users/interfaces/Users ';
import getCurrentUser from '../../../api/users/getCurrentUser';

interface PlanEmpleoUserProps {
	isFreelance: boolean;
}
interface CompanyInfoAndEppConfigI {
	company: CompanyInfoI;
	companyEppConfig: CompanyEppConfigI;
}
const emptyCompanyInfoAndEppConfig = {
	company: EMPTY_COMPANY_INFO,
	companyEppConfig: EMPTY_COMPANY_EPP_CONFIG
};

const PlanEmpleoUser: React.FC<PlanEmpleoUserProps> = ({ isFreelance }: PlanEmpleoUserProps) => {
	const {
		state: { translate }
	} = useContext(I18nContext);

	const { token, companyId } = useContext(ArcaContext);
	const [activeIndices, setActiveIndices] = useState<number[]>([]);
	const [portfolios, setPortfolios] = useState<Portfolio[]>([]);

	const [error, setError] = useState<string | null>(null);
	const [initialLoading, setInitialLoading] = useState<boolean>(true);

	const [companyEppInfo, setCompanyEppInfo] = useState<CompanyInfoAndEppConfigI>(
		emptyCompanyInfoAndEppConfig
	);
	const [currentUser, setCurrentUser] = useState<CurrentUserI>(EMPTY_CURRENT_USER);

	useEffect(() => {
		setInitialLoading(true);

		const getCompanyInfoEppConfigAsync = async () => {
			if (typeof companyId === 'string' && companyId.trim().length > 0) {
				try {
					const currentCompanyInfoEppConfig = await getCompanyInfoEppConfig(token, companyId);
					const currentCompanyInfoEppConfigData =
						currentCompanyInfoEppConfig.data as CompanyInfoAndEppConfigI;
					setCompanyEppInfo(currentCompanyInfoEppConfigData);
				} catch (error) {
					console.error('Error fetching company info:', error);
				}
			}
		};

		if (!isFreelance) {
			getCompanyInfoEppConfigAsync();
		}

		const getCurrentUserAsync = async () => {
			const currentUser = await getCurrentUser(token);
			const currentUserData = currentUser.data as CurrentUserI;
			setCurrentUser(currentUserData);
		};
		getCurrentUserAsync();

		const fetchPortfolioLines = async () => {
			try {
				const response = await getPortfolioLines(token);
				setPortfolios(response.data);
			} catch (err) {
				setError('Failed to fetch portfolio lines');
			}
		};
		fetchPortfolioLines();

		setTimeout(() => {
			setInitialLoading(false);
		}, 1000);
	}, [token]);

	const toggleCollapse = (index: number) => {
		if (activeIndices.includes(index)) {
			setActiveIndices(activeIndices.filter((i) => i !== index));
		} else {
			setActiveIndices([...activeIndices, index]);
		}
	};

	return (
		<div>
			{initialLoading ? (
				<Box h="80dvh" display="flex" justifyContent="center" alignItems="center">
					<LoadingViewSpinner />
				</Box>
			) : (
				<div>
					<SectionTitle text={translate('user.employmentPlan.info')} />
					<Text>{translate('user.employmentPlan.detail')}</Text>

					{error && <Text color="red">{error}</Text>}

					{portfolios.map((portfolio, index) => (
						<Box key={portfolio.portfolioCode} mb="4">
							<Button
								onClick={() => toggleCollapse(index)}
								w="100%"
								bg={ProjectConfig.ARCA_COLORS.PINK_200}
								color="black"
								_hover={{ bg: ProjectConfig.ARCA_COLORS.PINK_300 }}>
								{portfolio.portfolioCode}
							</Button>
							<Collapse in={activeIndices.includes(index)} animateOpacity style={{ width: '100%' }}>
								<Box w="100%" p="5" mt="4" borderWidth="1px" borderRadius="md" shadow="md">
									<VStack spacing="4" align="stretch" w="100%">
										{/* Header row */}
										<Box
											display="flex"
											justifyContent="space-between"
											p="2"
											borderBottom="1px"
											borderColor="gray.200"
											bg="gray.100"
											w="100%">
											<Text w="20%">{translate('user.employmentPlan.etfTitle')}</Text>
											<Text w="10%">{translate('user.employmentPlan.type')}</Text>
											<Text w="15%">{translate('user.employmentPlan.index')}</Text>
											<Text w="10%">{translate('user.employmentPlan.currency')}</Text>
											<Text w="10%">{translate('user.employmentPlan.size')}</Text>
											<Text w="10%">{translate('user.employmentPlan.expenses')}</Text>
											<Text w="10%">{translate('user.employmentPlan.trackingError')}</Text>
											<Text w="10%">{translate('user.employmentPlan.weight')}</Text>
										</Box>
										{/* Data rows */}
										{portfolio.lines.map((line) => (
											<Box
												key={line.lineId}
												display="flex"
												justifyContent="space-between"
												p="2"
												borderBottom="1px"
												borderColor="gray.200"
												w="100%">
												<Text w="20%">{line.name}</Text>
												<Text w="10%">{line.assetClass}</Text>
												<Text w="15%">{line.indexName}</Text>
												<Text w="10%">{line.currency}</Text>
												<Text w="10%">{line.sizeMeur}</Text>
												<Text w="10%">{line.expenses}</Text>
												<Text w="10%">{line.trackingError}</Text>
												<Text w="10%">{line.weight}</Text>
											</Box>
										))}
									</VStack>
								</Box>
							</Collapse>
						</Box>
					))}

					<SectionTitle text={translate('admin.setBenefit.documents')} />

					<DocumentDownload
						documentName={translate('user.employmentPlan.proforma')}
						downloadLink={
							isFreelance
								? currentUser.userEppConfig.linkRegulation
								: companyEppInfo.companyEppConfig.linkRegulation
						}
					/>

					<SectionTitle text={translate('admin.setBenefit.retreat')} />

					<Button
						color={ProjectConfig.ARCA_COLORS.BLACK}
						backgroundColor={ProjectConfig.ARCA_COLORS.PINK_200}
						_hover={{
							color: ProjectConfig.ARCA_COLORS.BLACK,
							background: ProjectConfig.ARCA_COLORS.PINK_300,
							fontWeight: 'bold',
							fontSize: '17px'
						}}>
						{translate('admin.setBenefit.retreatButtton')}
					</Button>
				</div>
			)}
		</div>
	);
};

export default PlanEmpleoUser;
