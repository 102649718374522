import { FiAlertCircle } from 'react-icons/fi';
import { Tooltip, Box } from '@chakra-ui/react';
import ProjectConfig from '../../constants';

/** #BOOSTME Pendiente de ver si se pudiera poner un blur alrededor del Tooltip para destacarlo aún más */

interface infoIconClickProps {
	text: string;
	isVisible: boolean;
}

const InfoIconClick: React.FC<infoIconClickProps> = ({ text, isVisible }) => {
	if (!isVisible) {
		return null;
	}

	return (
		<Tooltip
			label={text}
			openDelay={300}
			bg="gray.800"
			color="white"
			borderRadius="md"
			p="15px"
			maxW="200px"
			placement="right-start"
			fontSize="15px"
			shadow="dark-lg"
			colorScheme={ProjectConfig.ARCA_COLORS.PINK_500}>
			<Box as="span" color={ProjectConfig.ARCA_COLORS.PINK_500}>
				<FiAlertCircle />
			</Box>
		</Tooltip>
	);
};

export default InfoIconClick;
